import React from "react"

//React components imports
import Layout from "../components/Layout"
import ReservationAddressForm from "../components/ReservationAddressForm"
import PageTitle from "../components/PageTitle"

import ReservationText from "../components/ReservationText"

const ReservationBookigPage = props => {
  return (
    <Layout>
      {/* <PageTitle>Rezervácia</PageTitle>
      <ReservationAddressForm data={props.location.state} /> */}
      <ReservationText />
    </Layout>
  )
}

export default ReservationBookigPage
