import styled from "styled-components"

const TextArea = styled.textarea`
  width: 250px;
  border: none;
  background: transparent;
  color: ${props => props.theme.colors.text.white};
  border-bottom: 1px solid ${props => props.theme.colors.primary.green};
  padding-bottom: 10px;
  font-size: ${props => props.theme.fontSizes.normal};
  font-weight: 600;

  &:focus {
    outline: none;
    border-bottom: 2px solid ${props => props.theme.colors.primary.green};
    padding-bottom: 9px;
  }

  ::placeholder {
    color: "#878787";
  }

  &::after {
    content: "/100";
    height: 10px;
    background: deeppink;
  }
`

export default TextArea
