import styled from "styled-components"

const Note = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  /* or 164% */
  color: ${props => props.theme.colors.imageBorder.grey};
  text-align: center;
`

export default Note
