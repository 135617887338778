import styled from "styled-components"

const Line = styled.div`
  height: 1px;
  width: 100%;
  grid-column: 1/3;
  background: ${props => props.theme.colors.primary.green};
`

export default Line
