import styled from "styled-components"

const Title = styled.h4`
  font-size: 18px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.primary.green};
  font-weight: normal;
  margin-top: 32px;
`

export default Title
