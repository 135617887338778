import styled from "styled-components"

const Container = styled.div`
  display: grid;
  justify-items: center;

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin: auto;
    grid-template-columns: 1fr 365px;
    max-width: 850px;
    grid-gap: 24px 90px;
  }
`

export default Container
