import styled from "styled-components"

const Form = styled.form`
  display: grid;
  grid-gap: 32px;
  margin: 48px auto 24px;
  justify-items: center;
  width: 100%;

  @media (${props => props.theme.mediaQueries.desktop}) {
    justify-items: start;
    grid-column: 1/2;
    grid-row: 1/2;
  }
`

export default Form
