import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;
  margin: 18px auto 32px;
`

export default Container
