import styled from "styled-components"

const Text = styled.p`
  margin: 0 6px;

  :nth-of-type(even) {
    text-align: right;
  }
`

export default Text
