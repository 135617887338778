import styled from "styled-components"

const TextAreaCounter = styled.div`
  width: 100%;
  font-style: normal;
  text-align: right;
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.colors.imageBorder.grey};
  margin-top: 2px;
`
export default TextAreaCounter
